import React from 'react';
export interface CtaProps {
  text: string;
  customClass?: string;
  link: string;
}

const Cta: React.FC<CtaProps> = ({ link, customClass, text, ...props }) => {
  return (
    <>
      {text && link ? (
        <a
          href={link}
          className={`w-160 min-h-50 py-8 px-12 text-center no-defaults ripple hover:opacity-80 text-14 md:text-16 leading-22 font-bold border-yellow border text-black bg-yellow focus:outline-none disabled:opacity-20 flex justify-center items-center border border-transparent ${customClass}`}
          target="_blank"
          rel="noreferrer"
          font-customisation="para-text"
          button-customisation="page-button"
          {...props}
        >
          {text}
        </a>
      ) : null}
    </>
  );
};

export default Cta;
