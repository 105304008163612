import { ToastWithKeyType } from '@common/store/toasts/slice';
import { addToast, removeToast } from '@common/store/toasts/slice';
// import ToastCloseIcon from '@images/Close.svg';
import Green from '@images/GreenXMark.svg';
import ToastCloseIconPD from '@images/GreyXMark.svg';
import Red from '@images/RedXMark.svg';
// import classnames from 'classnames';
import ShoppingBag from '@images/Shopping.svg';
import React from 'react';

export interface ToastsProps {
  toasts: ToastWithKeyType[];
  addToast: typeof addToast;
  removeToast: typeof removeToast;
  quickView?: boolean;
}

const Toasts: React.FC<ToastsProps> = ({ toasts, removeToast, quickView }) => {
  setTimeout(() => {
    if (toasts.length) {
      document.getElementById('QuickViewToasts')?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, 1000);

  return (
    <>
      {quickView ? (
        <div className="container px-20 ml-4 ">
          {toasts.map((toast) => (
            <div
              key={toast.key}
              className={
                toast.variant === 'bg-toast-error'
                  ? 'xl:min-w-full xl:w-full bg-toastError text-errorToast border-errorToast border-l-4 border-t-0 border-b-0 border-r-0'
                  : 'xl:min-w-full xl:w-full bg-toastSuccess bg-opacity-16 text-successToast border-successToast border-l-4 border-t-0 border-b-0 border-r-0'
              }
            >
              <div className="container my-10 flex items-center py-10 text-15 justify-between">
                <div className="flex items-center">
                  <div className="font-bold">{toast.variant === 'bg-toast-error' ? 'Error!' : 'Success!'}</div>
                  <div
                    className="text-couponText ml-12 md:ml-26"
                    dangerouslySetInnerHTML={{ __html: toast.content }}
                  ></div>
                </div>
                <div onClick={() => removeToast(toast.key)}>
                  <img
                    src={toast.variant === 'bg-toast-error' ? Red : Green}
                    className={'cursor-pointer min-h-30 min-w-30'}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="min-w-full xl:min-w-1088">
          {toasts.map((toast) => (
            <div
              key={toast.key}
              className={
                toast.variant === 'bg-toast-error'
                  ? 'bg-toastBg  bg-opacity-25 mt-10 first:mt-0 text-black border-yellow border-l-7 border-t-0 border-b-0 border-r-0 min-w-full xl:min-w-1088'
                  : 'bg-toastBg  bg-opacity-25 mt-10 first:mt-0 text-black border-yellow border-l-7 border-t-0 border-b-0 border-r-0 min-w-full xl:min-w-1088'
              }
            >
              <div className="container px-10 md:px-20 xl:w-1088 flex items-center py-10 text-14 md:text-15 ">
                <img src={ShoppingBag} alt="bag" className="w-24 h-24 md:w-36 md:h-36 mr-14 md:mr-20" />
                <div className="flex justify-between items-center w-full" font-customisation="para-text">
                  <div>
                    <div className="font-bold">{toast.variant === 'bg-toast-error' ? 'Error!' : 'Success!'}</div>
                    <div className="text-couponText" dangerouslySetInnerHTML={{ __html: toast.content }}></div>
                  </div>
                  <div className="" onClick={() => removeToast(toast.key)}>
                    <img src={ToastCloseIconPD} alt="close" className="cursor-pointer h-34 w-34 md:h-44 md:w-44" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Toasts;
